@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'beskar/styles/focus.css';
@import './globals.css';

iframe {
    background-color: #111;
}

@layer base {
  :root {
        --radius: 0.5rem;
    }
}