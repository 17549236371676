:root {
    --page-max-width: 1200px;
    --pagePadding: 20px;
    /* Orange variable in framer */
    --token-64603892-5c8b-477a-82d6-e795e75dd5dc: #0b5c96; 
}

/* Fix all that shitty libraries (like chakra) that try to remove scroll bar and layout shift everything */
html > body {
    margin-right: 0 !important;
}
/* html {
    padding-right: 0 !important;
} */

* {
    box-sizing: border-box;
    border-color: theme('colors.gray.200');
}
.dark * {
    border-color: theme('colors.gray.600');
}

/* do not zoom on ios select */
select {
    font-size: 16px;
}

html {
    /* min-height: 100%; */
    background-color: theme('colors.gray.100');
    /* height: 100vh; */
    position: relative;
    overflow-x: hidden !important;
    /* overflow-y: scroll; */
    scroll-behavior: smooth;
    color: theme('colors.gray.700');
    touch-action: pan-x pan-y pinch-zoom !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
}

html.dark {
    background-color: theme('colors.gray.900');
    color: theme('colors.gray.200');
    color-scheme: dark;
}

#__next {
    min-height: 100vh !important;
    /* margin-right: calc(-1 * (100vw - 100%)); */
}
body {
    min-height: 100%;
    height: auto !important;
    position: relative;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

